document.addEventListener("DOMContentLoaded", function () {
  const bottomPcSearchBar = document.querySelector(".search-bar.-pc");
  const bottomSpSearchBar = document.querySelector(".search-bar.-sp.-sr");
  const bottomSpRecruitOnlySearchBar = document.querySelector(
    ".search-bar.-sp:has(.-recruit-only)"
  );

  function getCurrentLocation() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition((position) => {
        resolve(position);
      });
    });
  }

  function bindShopData(data) {
    const shop = data.shop;
    let address = "";

    const modalElement = document.getElementById("nearest-shop-modal-content");

    if (modalElement) {
      modalElement.innerHTML = data.modal_content;
    }

    if (shop.postal_code) {
      const postal_code = shop.postal_code
        .replace("-", "")
        .replace(/(\d{3})(\d{4})/, "$1-$2");
      address += `〒${postal_code} `;
    }

    if (shop.address) {
      address += shop.address;
    }

    document.querySelectorAll("[data-nearest-shop-name]").forEach((element) => {
      element.textContent = shop.name;
    });

    document
      .querySelectorAll("[data-nearest-shop-address]")
      .forEach((element) => {
        element.innerHTML = address;
      });

    document
      .querySelectorAll("[data-nearest-shop-phone]")
      .forEach((element) => {
        element.textContent = shop.phone_number;
      });

    document.querySelectorAll("[data-nearest-shop-fax]").forEach((element) => {
      element.textContent = shop.fax_number;
    });

    if (shop.image) {
      document
        .querySelectorAll("[data-nearest-shop-image]")
        .forEach((element) => {
          element.src = shop.image;
        });
    }

    document
      .querySelectorAll("[data-nearest-shop-address-link]")
      .forEach((element) => {
        element.href = `https://www.google.com/maps/search/?api=1&query=${shop.latitude},${shop.longitude}`;
      });

    document
      .querySelectorAll("[data-nearest-shop-phone-link]")
      .forEach((element) => {
        element.href = `tel:${shop.phone_number}`;
      });

    document.querySelectorAll("[data-nearest-shop-link]").forEach((element) => {
      element.href = shop.url;
    });

    document.querySelectorAll("[data-nearest-shop-name]").forEach((element) => {
      const parent = element.parentElement;
      parent.querySelectorAll("[data-services]").forEach((element) => {
        element.setAttribute("data-services", JSON.stringify(shop.services));
      });
    });

    document
      .querySelectorAll("[data-nearest-shop-link-menu-section]")
      .forEach((element) => {
        element.href = shop.url + "#menu-section";
      });

    if (shop.pdf) {
      const pdfServices = Object.keys(shop.pdf);

      pdfServices.forEach((pdfService) => {
        const pdfElement = document.querySelector(
          `[data-nearest-shop-pdf-service="${pdfService}"]`
        );

        if (!pdfElement) {
          return;
        }

        const pdfLinkElement = pdfElement.querySelector(
          "[data-nearest-shop-pdf-link]"
        );
        const pdfNameElement = pdfElement.querySelector(
          "[data-nearest-shop-pdf-name]"
        );
        const pdfSizeElement = pdfElement.querySelector(
          "[data-nearest-shop-pdf-size]"
        );

        if (pdfElement.tagName === "A") {
          pdfElement.href = shop.pdf[pdfService].url;
        }

        if (pdfLinkElement) {
          pdfLinkElement.href = shop.pdf[pdfService].url;
        }

        if (pdfNameElement) {
          pdfNameElement.textContent = shop.pdf[pdfService].name;
        }

        if (pdfSizeElement) {
          pdfSizeElement.textContent = shop.pdf[pdfService].size;
        }

        pdfElement.style.display = "block";
      });
    }

    if (bottomPcSearchBar) {
      bottomPcSearchBar.classList.remove("-denied");
    }

    if (bottomSpSearchBar) {
      bottomSpSearchBar.classList.remove("-denied");
    }

    if (bottomSpRecruitOnlySearchBar) {
      bottomSpRecruitOnlySearchBar.classList.add("-denied");
    }
  }

  async function getNearestShop(position = null) {
    if (!position) {
      position = await getCurrentLocation();
    }

    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    const response = await fetch(
      `/reserve/consumer/service-station/nearest?lat=${latitude}&lng=${longitude}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    );

    const data = await response.json();

    const shop = data.data.shop;

    if (!shop) {
      return;
    }

    localStorage.setItem(
      "nearest_shop",
      JSON.stringify({
        shop: shop,
        modal_content: data.data.modal_content,
      })
    );

    bindShopData({
      shop,
      modal_content: data.data.modal_content,
    });
  }

  document.querySelectorAll("[data-ss-search-nomap-form]").forEach((ele) => {
    ele.style.display = "block";
  });

  document.querySelectorAll("[data-ss-search-form]").forEach((ele) => {
    ele.style.display = "none";
  });

  document.querySelectorAll("[data-ss-search-form-header]").forEach((ele) => {
    ele.style.display = "none";
  });

  document.addEventListener("can-access-location", (event) => {
    document.querySelectorAll("[data-ss-search-nomap-form]").forEach((ele) => {
      ele.style.display = "none";
    });

    document.querySelectorAll("[data-ss-search-form]").forEach((ele) => {
      ele.style.display = "block";
    });

    document.querySelectorAll("[data-ss-search-form-header]").forEach((ele) => {
      ele.style.display = "block";
    });

    getNearestShop(event.detail.position);
  });

  document.addEventListener("load-old-nearest-shop", (event) => {
    const previousNearestShop = localStorage.getItem("nearest_shop");

    if (previousNearestShop) {
      bindShopData(JSON.parse(previousNearestShop));
    }
  });
});
